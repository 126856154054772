import Axios from 'axios';
import { put, takeLatest, select } from 'redux-saga/effects';
import { setAlarms } from '../bravo/alarmsSlice';
import { selectActiveCompany } from '../../auth/store/userSlice';

function* loadAlarmsAsync() {
    const activeCompany = yield select(selectActiveCompany);
    const { id: companyId } = activeCompany;
    const response = yield Axios.get(`${process.env.REACT_APP_BACKEND_API_HOST}/api/companies/${companyId}/alarms`);
    const data = yield response.data;
    yield put(setAlarms(data));
}

export default function* userSagas() {
    yield takeLatest('auth/user/updateActiveCompany', loadAlarmsAsync);
    yield takeLatest('bravo/alarms/reloadAlarms', loadAlarmsAsync);
}
