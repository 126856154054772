// Internet Explorer 11 requires polyfills and partially supported by this project.
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import '@fontsource/muli';

import './i18n';
import './styles/index.css';
import App from 'app/App';
import countries from 'i18n-iso-countries';
import { BravoProxyConfig } from 'mfbravoreactlib';
import * as serviceWorker from './serviceWorker';

BravoProxyConfig.apiBackend = process.env.REACT_APP_BACKEND_API_HOST;
BravoProxyConfig.configBackend = process.env.REACT_APP_CONFIG_API_HOST;
BravoProxyConfig.websocketHost = `${process.env.REACT_APP_BACKEND_DEVICE_WS_HOST}/device`;

// register support country iso codes
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
