import DayjsUtils from '@date-io/dayjs';

import MFAuthorization from '@microfan/core/MFAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import history from '@history';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createGenerateClassName, jssPreset, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import rtl from 'jss-rtl';
import React from 'react';
import Provider from 'react-redux/es/components/Provider';
import { Router } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import AppContext from './AppContext';
import { Auth } from './auth';
import routes from './fuse-configs/routesConfig';
import store from './store';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const jss = create({
    ...jssPreset(),
    plugins: [...jssPreset().plugins, jssExtend(), rtl()],
    insertionPoint: document.getElementById('jss-insertion-point'),
});

const generateClassName = createGenerateClassName();

const App = () => {
    return (
        <AppContext.Provider
            value={{
                routes,
            }}
        >
            <QueryClientProvider client={queryClient}>
                <StylesProvider jss={jss} generateClassName={generateClassName}>
                    <Provider store={store}>
                        <MuiPickersUtilsProvider utils={DayjsUtils}>
                            <Auth>
                                <Router history={history}>
                                    <MFAuthorization>
                                        <FuseTheme>
                                            <FuseLayout />
                                        </FuseTheme>
                                    </MFAuthorization>
                                </Router>
                            </Auth>
                        </MuiPickersUtilsProvider>
                    </Provider>
                </StylesProvider>
                {process.env.NODE_ENV === 'development' && (
                    <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
                )}
            </QueryClientProvider>
        </AppContext.Provider>
    );
};

export default App;
