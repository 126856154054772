import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { getSafeSystemId } from 'app/helpers/deviceHelper';
import { has, isBoolean, isString, pick, toNumber, uniq, values } from 'lodash';

const selectSelf = (state) => state.bravo.alarms;
export const selectActiveAlarms = createSelector(selectSelf, (state) => state.items);
export const selectActiveAlarmsCount = createSelector(selectSelf, (state) => {
    let counter = 0;
    values(state.items).forEach((arr) => {
        counter += arr.length;
    });
    return counter;
});

const initialState = {
    items: {},
};

const alarmsSlice = createSlice({
    name: 'bravo/alarms',
    initialState,
    reducers: {
        setAlarms: (state, action) => {
            state.items = action.payload;
        },
        reloadAlarms: (state, action) => state,
    },
});

export const { setAlarms, reloadAlarms } = alarmsSlice.actions;

export default alarmsSlice.reducer;
