import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import Axios from 'axios';
import jwtDecode from 'jwt-decode';
import { isArray } from 'lodash';
import AxiosOAuth from 'axios-oauth-client';
/* eslint-disable camelcase */

const CLIENT_ID = process.env.REACT_APP_AUTH_SERVER_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_AUTH_SERVER_CLIENT_SECRET;

class JwtService extends FuseUtils.EventEmitter {
    init() {
        this.setInterceptors();
        this.handleAuthentication();
    }

    setInterceptors = () => {
        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (err) => {
                return new Promise((resolve, reject) => {
                    if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
                        // if you ever get an unauthorized response, logout the user
                        this.emit('onAutoLogout', 'Invalid access_token');
                        this.setSession(null);
                    }
                    throw err;
                });
            }
        );
    };

    handleAuthentication = () => {
        const access_token = this.getAccessToken();
        const refresh_token = this.getRefreshToken();

        if (!access_token && !refresh_token) {
            this.emit('onNoAccessToken');
            return;
        }

        if (this.isAuthTokenValid(access_token)) {
            this.setSession(access_token, refresh_token);
            this.emit('onAutoLogin', true);
        } else {
            this.setSession(null);
            this.emit('onAutoLogout', 'access_token expired');
        }
    };

    createUser = (data) => {
        return new Promise((resolve, reject) => {
            // axios.post('/api/auth/register', data).then((response) => {
            //     if (response.data.user) {
            //         this.setSession(response.data.access_token);
            //         resolve(response.data.user);
            //     } else {
            //         reject(response.data.error);
            //     }
            // });
        });
    };

    signInWithEmailAndPassword = (email, password) => {
        const getClientCredentials = AxiosOAuth.client(Axios.create(), {
            url: `${process.env.REACT_APP_AUTH_SERVER_API_HOST}/connect/token`,
            grant_type: 'password',
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            username: email,
            password,
            scope: 'bravo_default offline_access openid',
        });

        return new Promise(async (resolve, reject) => {
            try {
                // login
                const response = await getClientCredentials();
                const { access_token, refresh_token } = response;
                this.setSession(access_token, refresh_token);

                const decoded = jwtDecode(access_token);
                const transform = this.transformToken(decoded, access_token);

                resolve(transform.user);

                return resolve(response);
            } catch (err) {
                if (err.response.data) reject(err.response.data.error);
                else reject(new Error('unknown_error'));
            }
        });
    };

    signInWithToken = () => {
        return new Promise((resolve, reject) => {
            // workaround
            const accessToken = this.getAccessToken();
            const refreshToken = this.getRefreshToken();
            const decoded = jwtDecode(accessToken);
            const valid = !decoded || Date.now() < decoded.exp * 1000;

            if (!valid) {
                this.logout();
                reject(new Error('Failed to login with token.'));
            } else {
                const transform = this.transformToken(decoded, accessToken);
                this.setSession(transform.access_token, refreshToken);
                resolve(transform.user);
            }
        });
    };

    updateUserData = (user) => {
        return axios.post('/api/auth/user/update', {
            user,
        });
    };

    setSession = (access_token, refresh_token = null) => {
        if (access_token) {
            localStorage.setItem('jwt_access_token', access_token);
            localStorage.setItem('jwt_refresh_token', refresh_token);
            axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
            axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API_HOST;
        } else {
            localStorage.removeItem('jwt_access_token');
            localStorage.removeItem('jwt_refresh_token');
            delete axios.defaults.headers.common.Authorization;
            axios.defaults.baseURL = null;
        }
    };

    logout = () => {
        this.setSession(null);
    };

    isAuthTokenValid = (access_token) => {
        if (!access_token) {
            return false;
        }
        const decoded = jwtDecode(access_token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return false;
        }

        return true;
    };

    getAccessToken = () => {
        return localStorage.getItem('jwt_access_token');
    };

    getRefreshToken = () => {
        return localStorage.getItem('jwt_refresh_token');
    };

    transformToken = (data, access_token) => {
        const { name, email, role, sub, lng } = data;

        return {
            user: {
                role: isArray(role) ? [...role, 'bravo-default'] : [role, 'bravo-default'],
                data: {
                    id: sub,
                    displayName: name,
                    lng: lng || 'en',
                    email,
                    settings: {},
                },
            },
            access_token,
        };
    };
}

const instance = new JwtService();

export default instance;
