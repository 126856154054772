import { last, range } from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
// created, trialing, active, canceled, past_due, trial_expired, payment_failed, payment_action_required, unpaid, incomplete, incomplete_expired, canceled_at_period_end

export default class SubscriptionUtil {
    static isActive = (subscription) => {
        if (subscription.status === 'active') {
            return true;
        }

        // trailing
        if (this.isTrialing(subscription) && this.trialingExpirationInDays(subscription) > 0) {
            return true;
        }

        return false;
    };

    static trialingExpirationInDays = (subscription) => {
        if (!this.isTrialing(subscription) || !subscription.currentPeriodEnd) {
            return 0;
        }

        const end = dayjs.utc(subscription.currentPeriodEnd).endOf('day');
        const differenceInDays = end.diff(dayjs.utc().endOf('day'), 'days');
        return differenceInDays;
    };

    static cancelExpirationInDays = (subscription) => {
        const end = dayjs.utc(subscription.currentPeriodEnd).endOf('day');
        const differenceInDays = end.diff(dayjs.utc().endOf('day'), 'days');
        return differenceInDays;
    };

    static isTrialing = (subscription) => subscription.status === 'trialing';

    static isPro = (subscription) => {
        return this.isActive(subscription) && subscription.plan === 'pro';
    };

    static isStarter = (subscription) => {
        return this.isActive(subscription) && subscription.plan === 'starter';
    };

    static maxUsers = (subscription) => {
        return this.isPro(subscription) ? 10 : 1;
    };

    static calculatePrice = (tiers, numberOfDevices, VAT) => {
        const priceForTier = (tier) => {
            return (tier.unit_amount ?? 0) + (tier.flat_amount ?? 0);
        };

        const price = range(0, numberOfDevices + 1).reduce((total, deviceNumber) => {
            const tier = tiers.find((t) => deviceNumber <= t.up_to) ?? last(tiers);
            return total + priceForTier(tier);
        });

        if (VAT > 0) {
            return (VAT + 1) * price;
        }

        return price;
    };

    static getVATTypes = (countryCode) => {
        if (countryCode === undefined) return [];
        switch (countryCode.toUpperCase()) {
            case 'AE': // United Arab Emirates
                return [{ code: 'ae_trn', desc: 'United Arab Emirates TRN' }];
            case 'AU': // Australia
                return [{ code: 'au_abn', desc: 'Australian Business Number (AU ABN)' }];
            case 'BR': // Brazil
                return [
                    { code: 'br_cnpj', desc: 'Brazilian CNPJ number' },
                    { code: 'br_cpf', desc: 'Brazilian CPF number' },
                ];
            case 'CA': // Canada
                return [
                    { code: 'ca_bn', desc: 'Canadian BN' },
                    { code: 'ca_qst', desc: 'Canadian QST number' },
                ];
            case 'CH': // Switzerland
                return [{ code: 'ch_vat', desc: 'Switzerland VAT number' }];
            case 'CL': // Chile
                return [{ code: 'cl_tin', desc: 'Chilean TIN' }];
            case 'ES': // Spain
                return [
                    { code: 'es_cif', desc: 'Spanish CIF number' },
                    { code: 'eu_vat', desc: 'European VAT number' },
                ];
            case 'AT': // Austria
            case 'BE': // Belgium
            case 'BG': // Bulgaria
            case 'CY': // Cyprus
            case 'CZ': // Czech Republic
            case 'DE': // Germany
            case 'DK': // Denmark
            case 'EE': // Estonia
            case 'FI': // Finland
            case 'FR': // France
            case 'GB': // United Kingdom
            case 'GR': // Greece
            case 'HR': // Croatia
            case 'HU': // Hungary
            case 'IE': // Ireland
            case 'IT': // Italy
            case 'LT': // Lithuania
            case 'LU': // Luxembourg
            case 'LV': // Latvia
            case 'MT': // Malta
            case 'NL': // Netherlands
            case 'PL': // Poland
            case 'PT': // Portugal
            case 'RO': // Romania
            case 'SE': // Sweden
            case 'SI': // Slovenia
            case 'SK': // Slovakia
                return [{ code: 'eu_vat', desc: 'European VAT number' }];
            case 'HK': // Hong Kong
                return [{ code: 'hk_br', desc: 'Hong Kong BR number' }];
            case 'ID': // Indonesia
                return [{ code: 'id_npwp', desc: 'Indonesian NPWP number' }];
            case 'IN': // India
                return [{ code: 'in_gst', desc: 'Indian GST number' }];
            case 'JP': // Japan
                return [
                    { code: 'jp_cn', desc: 'Japanese Corporate Number' },
                    { code: 'jp_rn', desc: 'Japanese Registered Foreign Businesses' },
                ];
            case 'KR': // Korea, Republic of
                return [{ code: 'kr_brn', desc: 'Korean BRN' }];
            case 'LI': // Liechtenstein
                return [{ code: 'li_uid', desc: 'Liechtensteinian UID number' }];
            case 'MX': // Mexico
                return [{ code: 'mx_rfc', desc: 'Mexican RFC number' }];
            case 'MY': // Malaysia
                return [
                    { code: 'my_frp', desc: 'Malaysian FRP number' },
                    { code: 'my_itn', desc: 'Malaysian ITN' },
                    { code: 'my_sst', desc: 'Malaysian SST number' },
                ];
            case 'NO': // Norway
                return [{ code: 'no_vat', desc: 'Norwegian VAT number' }];
            case 'NZ': //
                return [{ code: 'nz_gst', desc: 'New Zealand GST number' }];
            case 'RU': // Russian Federation
                return [
                    { code: 'ru_inn', desc: 'Russian INN' },
                    { code: 'ru_kpp', desc: 'Russian KPP' },
                ];
            case 'SA': // Saudi Arabia
                return [{ code: 'sa_vat', desc: 'Saudi Arabia VAT' }];
            case 'SG': // Singapore
                return [
                    { code: 'sg_gst', desc: 'Singaporean GST' },
                    { code: 'sg_uen', desc: 'Singaporean UEN' },
                ];
            case 'TH': // Thailand
                return [{ code: 'th_vat', desc: 'Thai VAT' }];
            case 'TW': // Taiwan
                return [{ code: 'tw_vat', desc: 'Taiwanese VAT' }];
            case 'US': // United States
                return [{ code: 'us_ein', desc: 'United States EIN' }];
            case 'ZA': // South Africa
                return [{ code: 'za_vat', desc: 'South African VAT number' }];
            default:
                return [];
        }
    };

    static validateVAT = (vatType, vat) => {
        switch (vatType) {
            // case 'ae_trn':
            //     return /^(\d *?){15}$/.test(vat);
            // case 'au_abn':
            //     return /^(\d *?){11}$/.test(vat);
            // case 'br_cnpj':
            //     return /\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}/.test(vat);
            // case 'br_cpf':
            //     return /\d{3}\.\d{3}\.\d{3}\-\d{2}/.test(vat);
            case 'eu_vat':
                return /^(ATU[0-9]{8}|BE[01][0-9]{9}|BG[0-9]{9,10}|HR[0-9]{11}|CY[A-Z0-9]{9}|CZ[0-9]{8,10}|DK[0-9]{8}|EE[0-9]{9}|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|DE[0-9]{9}|EL[0-9]{9}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LV[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SK[0-9]{10}|SI[0-9]{8}|ES[A-Z]([0-9]{8}|[0-9]{7}[A-Z])|SE[0-9]{12}|GB([0-9]{9}|[0-9]{12}|GD[0-4][0-9]{2}|HA[5-9][0-9]{2}))$/.test(
                    vat
                );
            default:
                return true;
        }
    };

    static subscriptions = {
        starter: {
            plan: 'starter',
            label: 'STARTER',
            highlights: [
                { label: 'Read & Adjust', available: true },
                { label: 'View & Reset alarms', available: true },
                { label: '1 User', available: true },
                { label: 'Up to 10 Devices', available: true },
            ],
            maxDevices: 10,
            tiers: [
                {
                    flat_amount: 4200,
                    flat_amount_decimal: '5000',
                    unit_amount: 600,
                    unit_amount_decimal: '600',
                    up_to: 1,
                },
                {
                    flat_amount: null,
                    flat_amount_decimal: null,
                    unit_amount: 600,
                    unit_amount_decimal: '600',
                    up_to: 10,
                },
                {
                    flat_amount: null,
                    flat_amount_decimal: null,
                    unit_amount: 550,
                    unit_amount_decimal: '550',
                    up_to: null,
                },
            ],
        },
        pro: {
            plan: 'pro',
            label: 'PRO',
            highlights: [
                { label: 'Read & Adjust', available: true },
                { label: 'View & Reset alarms', available: true },
                { label: 'Up to 10 Users', available: true },
                { label: 'Up to 50 Devices', available: true },
                { label: 'Logging & Graphs', available: false },
                // { label: 'Site management', available: false },
                { label: 'Alarm notifications', available: false },
            ],
            maxDevices: 50,
            tiers: [
                {
                    flat_amount: 6000,
                    flat_amount_decimal: '6000',
                    unit_amount: 800,
                    unit_amount_decimal: '800',
                    up_to: 1,
                },
                {
                    flat_amount: null,
                    flat_amount_decimal: null,
                    unit_amount: 800,
                    unit_amount_decimal: '800',
                    up_to: 4,
                },
                {
                    flat_amount: null,
                    flat_amount_decimal: null,
                    unit_amount: 700,
                    unit_amount_decimal: '700',
                    up_to: 9,
                },
                {
                    flat_amount: null,
                    flat_amount_decimal: null,
                    unit_amount: 500,
                    unit_amount_decimal: '500',
                    up_to: 24,
                },
                {
                    flat_amount: null,
                    flat_amount_decimal: null,
                    unit_amount: 300,
                    unit_amount_decimal: '300',
                    up_to: null,
                },
            ],
        },
    };
}
