import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { isNil } from 'lodash';
import { setActiveCompany } from 'app/auth/store/userSlice';

const useStyles = makeStyles(() => ({
    userCompany: {
        paddingTop: 5,
        '&:hover': {
            backgroundColor: '#143567',
            textDecoration: 'underline',
        },
    },
}));

function UserSelectedCompany() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const companiesState = useSelector(({ auth }) => auth.companies);
    const userState = useSelector(({ auth }) => auth.user);
    const showMenu = (event) => setAnchorEl(event.currentTarget);

    const menuClick = (userCompany) => {
        // TODO: When switching companies, this call needs to navigate back to the main page
        dispatch(setActiveCompany(userCompany, true));
        setAnchorEl(null);
    };

    if (isNil(userState.activeCompany)) {
        return null;
    }

    return (
        <React.Fragment>
            <div
                onClick={(event) => {
                    showMenu(event);
                }}
                className={clsx(
                    classes.userCompany,
                    'active-company flex flex-row justify-center px-12 cursor-pointer rounded'
                )}
            >
                <div className='px-2 pt-3 text-12'>{userState.activeCompany.companyName}</div>
                <div className='px-2 pt-3'>
                    <Icon style={{ fontSize: 16 }}>arrow_drop_down</Icon>
                </div>
            </div>
            <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)}>
                {companiesState.items.map((userCompany) => (
                    <MenuItem key={userCompany.id} onClick={() => menuClick(userCompany)}>
                        <ListItemText>{userCompany.companyName}</ListItemText>
                    </MenuItem>
                ))}
                {/* <MenuItem divider />
                <MenuItem>
                    <ListItemIcon>
                        <Icon>add</Icon>
                    </ListItemIcon>
                    <ListItemText>Create company</ListItemText>
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <Icon>link</Icon>
                    </ListItemIcon>
                    <ListItemText>Join company</ListItemText>
                </MenuItem> */}
            </Menu>
        </React.Fragment>
    );
}

export default UserSelectedCompany;
