import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { getSafeSystemId } from 'app/helpers/deviceHelper';
import { has, isBoolean, isString, pick, toNumber, uniq } from "lodash";

const getSlice = (state) => state.bravo.devices;
export const selectDevices = createSelector(getSlice, (slice) => slice.items);
export const getDevices = createAction('bravo/devices/getDevices');
export const selectUniqueProductReferences = createSelector(selectDevices, (devices) =>
    uniq(Object.keys(devices).map((key) => devices[key].product))
);

const initialState = {
    loading: true,
    error: null,
    items: {},
};

const devicesSlice = createSlice({
    name: 'bravo/devices',
    initialState,
    reducers: {
        getDevicesStart: (state, action) => {
            state.items = {};
            state.loading = true;
            state.error = null;
        },
        getDevicesSuccess: {
            reducer: (state, action) => {
                state.items = action.payload;
                state.loading = false;
                state.error = null;
            },
            prepare: (data) => {
                const payload = {};

                data.forEach((item) => {
                    const safeSystemId = getSafeSystemId(item.systemId);
                    payload[safeSystemId] = {
                        id: item.deviceId,
                        name: item.name,
                        hasAlarm: item.hasAlarm,
                        version: item.hmiVersion,
                        lastOnline: item.lastOnline,
                        product: item.product,
                        systemId: item.systemId,
                        systemPin: item.systemPin,
                        safeSystemId,
                        isOnline: item.online,
                        hasSubscription: item.hasSubscription,
                        group: item.group,
                    };
                });

                return { payload };
            },
        },
        getDevicesFailed: (state, action) => {
            state.items = {};
            state.loading = false;
            state.error = action.payload;
        },
        updateDeviceState: (state, action) => {
            const { systemId, online } = action.payload;
            if (has(state.items, systemId)) {
                let isOnline = false;
                if (!isString(online) && isBoolean(online)) isOnline = online;
                else {
                    isOnline = toNumber(online) === 1;
                }
                state.items[systemId].isOnline = isOnline;
            }
        },
        updateAlarmState: (state, action) => {
            const { systemId } = action.payload;
            if (has(state.items, systemId)) {
                state.items[systemId].hasAlarm = action.payload.hasAlarm;
            }
        },
    },
});

export const { getDevicesStart, getDevicesSuccess, getDevicesFailed, updateDeviceState, updateAlarmState } =
    devicesSlice.actions;

export default devicesSlice.reducer;
