import { combineReducers } from '@reduxjs/toolkit';
import devices from './devicesSlice';
import employees from './employeesSlice';
import subscription from './subscriptionSlice';
import alarms from './alarmsSlice';

const bravoReducers = combineReducers({
    devices,
    employees,
    subscription,
    alarms,
});

export default bravoReducers;
