import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    error: null,
    current: null,
};

const selectSelf = (state) => state.bravo.subscription;
export const selectCurrentSubscription = createSelector(selectSelf, (state) => state.current);

const subscriptionSlice = createSlice({
    name: 'bravo/subscription',
    initialState,
    reducers: {
        getSubscriptionStart: (state, action) => {
            state.current = null;
            state.loading = true;
            state.error = null;
        },
        getSubscriptionSuccess: (state, action) => {
            state.current = action.payload;

            // // trialing
            // state.current = {
            //     status: 'trialing',
            //     numberOfDevices: 50,
            //     plan: 'pro',
            //     trialStart: moment.utc().subtract(0, 'days').toISOString(),
            //     trialEnd: moment.utc().add(2, 'days').toISOString(),
            //     currentPeriodStart: null,
            //     currentPeriodEnd: null,
            // };

            // // trial_expired
            // state.current = {
            //     status: 'trial_expired',
            //     numberOfDevices: 50,
            //     plan: 'pro',
            //     trialStart: null,
            //     trialEnd: null,
            //     currentPeriodStart: null,
            //     currentPeriodEnd: null,
            // };

            // // cancelled
            // state.current = {
            //     status: 'canceled',
            //     numberOfDevices: 50,
            //     plan: 'pro',
            //     trialStart: null,
            //     trialEnd: null,
            //     currentPeriodStart: null,
            //     currentPeriodEnd: moment.utc().add(2, 'days').toISOString(),
            // };

            // // canceled_at_period_end
            // state.current = {
            //     status: 'canceled_at_period_end',
            //     numberOfDevices: 50,
            //     plan: 'pro',
            //     trialStart: null,
            //     trialEnd: null,
            //     currentPeriodStart: moment.utc().subtract(0, 'days').toISOString(),
            //     currentPeriodEnd: moment.utc().add(2, 'days').toISOString(),
            // };

            // // canceled_at_period_end
            // state.current = {
            //     status: 'past_due',
            //     numberOfDevices: 50,
            //     plan: 'pro',
            //     trialStart: null,
            //     trialEnd: null,
            //     currentPeriodStart: null,
            //     currentPeriodEnd: null,
            // };

            // // active
            // state.current = {
            //     status: 'active',
            //     numberOfDevices: 2,
            //     plan: 'pro',
            //     trialStart: null,
            //     trialEnd: null,
            //     currentPeriodStart: moment.utc().add(-2, 'months').toISOString(),
            //     currentPeriodEnd: moment.utc().add(2, 'months').toISOString(),
            // };

            state.loading = false;
            state.error = null;
        },
        getSubscriptionFailed: (state, action) => {
            state.current = null;
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const getActiveSubscription = () => ({ bravo }) => bravo.subscription.current;

export const { getSubscriptionStart, getSubscriptionSuccess, getSubscriptionFailed } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
