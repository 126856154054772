import { TextFieldFormsy } from '@fuse/core/formsy';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import Formsy from 'formsy-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useHistory } from 'react-router';

function ForgotPasswordForm(props) {
    const dispatch = useDispatch();
    const register = useSelector(({ auth }) => auth.register);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const [isFormValid, setIsFormValid] = useState(false);
    const formRef = useRef(null);

    useEffect(() => {
        if (register.error && (register.error.username || register.error.password || register.error.email)) {
            formRef.current.updateInputsWithError({
                ...register.error,
            });
            disableButton();
        }
    }, [register.error]);

    function disableButton() {
        setIsFormValid(false);
    }

    function enableButton() {
        setIsFormValid(true);
    }

    const handleSubmit = async (model) => {
        try {
            setIsLoading(true);
            const url = `${process.env.REACT_APP_AUTH_SERVER_API_HOST}/api/users/forgot_password?client=bravo&email=${model.email}`;
            const response = await Axios.get(url);
            dispatch(
                showMessage({
                    message: 'An email message has been sent out with instructions on how to reset your password',
                    variant: 'success',
                })
            );
        } catch (err) {
            dispatch(showMessage({ message: 'An error occured, please try again', variant: 'error' }));
        } finally {
            setIsLoading(false);
            history.push('/login');
        }
    };

    return (
        <div className='w-full'>
            <Formsy
                onValidSubmit={handleSubmit}
                onValid={enableButton}
                onInvalid={disableButton}
                ref={formRef}
                className='flex flex-col justify-center w-full'
            >
                <TextFieldFormsy
                    className='mb-16'
                    type='text'
                    name='email'
                    label='Email'
                    validations='isEmail'
                    validationErrors={{
                        isEmail: 'Please enter a valid email',
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <Icon className='text-20' color='action'>
                                    email
                                </Icon>
                            </InputAdornment>
                        ),
                    }}
                    variant='outlined'
                    required
                />

                <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    className='w-full mx-auto mt-16 normal-case'
                    aria-label='SEND RESET LINK'
                    disabled={!isFormValid || isLoading}
                    value='legacy'
                >
                    SEND RESET LINK
                </Button>
            </Formsy>
        </div>
    );
}

export default ForgotPasswordForm;
