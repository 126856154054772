import _ from '@lodash';
import TextField from '@material-ui/core/TextField';
import { withFormsy } from 'formsy-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDebounce } from '@react-hook/debounce';

function TextFieldFormsy(props) {
    const importedProps = _.pick(props, [
        'autoComplete',
        'autoFocus',
        'children',
        'className',
        'defaultValue',
        'disabled',
        'FormHelperTextProps',
        'fullWidth',
        'id',
        'InputLabelProps',
        'inputProps',
        'InputProps',
        'inputRef',
        'label',
        'multiline',
        'name',
        'onBlur',
        'onChange',
        'onFocus',
        'placeholder',
        'required',
        'rows',
        'rowsMax',
        'select',
        'SelectProps',
        'type',
        'variant',
        'error',
    ]);

    const { errorMessage } = props;
    const [value, setValue] = useState(props.value || '');
    const [debouncedValue, setDebouncedValue] = useDebounce(value, 500);
    const didMountRef = useRef(false);

    const changeValue = (event) => {
        setValue(event.currentTarget.value);
        setDebouncedValue(event.currentTarget.value);
    };

    useEffect(() => {
        if (didMountRef.current) {
            props.setValue(debouncedValue);
            if (props.onChange) {
                props.onChange(debouncedValue);
            }
        }
        didMountRef.current = true;
    }, [debouncedValue]);

    return (
        <TextField
            {...importedProps}
            onChange={changeValue}
            value={value}
            error={Boolean((!props.isPristine && props.showRequired) || errorMessage)}
            helperText={errorMessage}
        />
    );
}

export default React.memo(withFormsy(TextFieldFormsy));
