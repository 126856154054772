import FuseUtils from '@fuse/utils';
import AppContext from 'app/AppContext';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchRoutes } from 'react-router-config';
import { withRouter } from 'react-router-dom';
import { isNil } from 'lodash';

class MFAuthorization extends Component {
    constructor(props, context) {
        super(props);
        const { routes } = context;
        this.state = {
            accessGranted: true,
            activeCompany: false,
            routes,
        };
    }

    componentDidMount() {
        if (!this.state.accessGranted) {
            this.redirectRoute();
        } else if (
            this.props.userRole.length > 0 &&
            this.state.accessGranted &&
            !this.state.activeCompany
        ) {
            // Check if userRole has any values in it,
            // if not, it's a guest and they should not be redirected despite having accessGranted=true
            this.redirectCompanySelectRoute();
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (
            nextState.accessGranted !== this.state.accessGranted || nextState.activeCompany !== this.state.activeCompany
        );
    }

    componentDidUpdate() {
        if (!this.state.accessGranted) {
            this.redirectRoute();
        } else if (this.state.accessGranted && !this.state.activeCompany) {
            this.redirectCompanySelectRoute();
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { location, userRole, userCompany } = props;
        const { pathname } = location;
        const redirectUrl = state && state.redirectUrl ? state.redirectUrl : '/';
        const matched = matchRoutes(state.routes, pathname)[0];

        return {
            accessGranted: matched ? FuseUtils.hasPermission(matched.route.auth, userRole) : true,
            activeCompany: !isNil(userCompany),
        };
    }

    redirectRoute() {
        const { location, userRole, history } = this.props;
        const { pathname, state } = location;
        const redirectUrl = state && state.redirectUrl ? state.redirectUrl : '/';

        // user is guest
        // redirect to Login Page
        if (!userRole || userRole.length === 0) {
            history.push({
                pathname: '/login',
                state: { redirectUrl: pathname },
            });
        } else {
            // user is member
            // user must be on unAuthorized page or just logged in
            // redirect to dashboard or redirectUrl
            history.push({
                pathname: redirectUrl,
            });
        }
    }

    redirectCompanySelectRoute() {
        const { location, history } = this.props;
        const { pathname, state } = location;
        const redirectUrl = state && state.redirectUrl ? state.redirectUrl : '/';

        if (pathname !== '/login' && pathname !== '/register') {
            history.push({
                pathname: '/select-company',
                state: { redirectUrl: pathname },
            });
        }
    }

    render() {
        return this.state.accessGranted ? <>{this.props.children}</> : null;
    }
}

function mapStateToProps({ auth }) {
    return {
        userRole: auth.user.role,
        userCompany: auth.user.activeCompany,
    };
}

MFAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps)(MFAuthorization));
