import React from 'react';
import { Redirect } from 'react-router-dom';

const AlarmsAppConfig = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: '/apps/alarms-app/alarm-cockpit',
            component: React.lazy(() => import('./alarm-cockpit/AlarmsCockpit')),
        },
        {
            path: '/apps/alarms-app',
            component: () => <Redirect to='/apps/alarms-app/alarm-cockpit' />,
        },
    ],
};

export default AlarmsAppConfig;
