import i18next from 'i18next';

import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'devices',
                title: 'Devices',
                type: 'item',
                icon: 'desktop_windows',
                url: '/apps/devices-app',
            },
            {
                id: 'alarms',
                title: 'Alarms',
                type: 'item',
                icon: 'notifications',
                url: '/apps/alarms-app',
                plan: ['pro'],
            },
            {
                id: 'company',
                title: 'Company',
                type: 'collapse',
                icon: 'business',
                permissions: ['isOwner'],
                children: [
                    {
                        id: 'company_employees',
                        title: 'Employees',
                        type: 'item',
                        url: '/apps/company-app/employees',
                        plan: ['pro'],
                        permissions: ['isOwner'],
                    },
                    {
                        id: 'company_subscription',
                        title: 'Subscription',
                        type: 'item',
                        url: '/apps/company-app/subscription',
                        permissions: ['isOwner'],
                        // badge: {
                        //     title: 'pro',
                        //     bg: '#112f5b',
                        //     fg: '#FFFFFF',
                        // },
                    },
                ],
            },
        ],
    },
];

export default navigationConfig;
