import QuickPanel from 'app/fuse-layouts/shared-components/quickPanel/QuickPanel';
import React from 'react';

function RightSideLayout1(props) {
    return (
        <>

            <QuickPanel />
        </>
    );
}

export default React.memo(RightSideLayout1);
