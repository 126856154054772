import JwtService from 'app/services/jwtService';
import { isEmpty, keys } from 'lodash';
import { END, eventChannel } from 'redux-saga';
import { actionChannel, all, cancel, cancelled, delay, fork, put, select, take } from 'redux-saga/effects';
import { updateAlarmState, updateDeviceState } from '../bravo/devicesSlice';
import { reloadAlarms } from '../bravo/alarmsSlice';

window.deviceWebSocket = null;
window.deviceSubscriptions = [];

function* listenMessages() {
    // create event channel to listen to websocket events
    const webSocketChannel = eventChannel((emit) => {
        const createWS = () => {
            const accessToken = JwtService.getAccessToken();
            if (!JwtService.isAuthTokenValid(accessToken)) return;
            console.log('Create socket connection');

            window.deviceWebSocket = new WebSocket(`${process.env.REACT_APP_BACKEND_DEVICE_WS_HOST}/device`, [
                `at-${accessToken}`,
            ]);

            window.deviceWebSocket.onmessage = (message) => {
                try {
                    const payload = JSON.parse(message.data);
                    // console.info(payload);
                    emit({ type: 'deviceWebSocket/onMessage', payload });
                } catch (err) {
                    console.info('Failed to process socket message');
                    console.info(err);
                }
            };
            window.deviceWebSocket.onopen = (event) => {
                emit({ type: 'deviceWebSocket/status/opened' });
                console.log('Socket connection created');
            };
            window.deviceWebSocket.onclose = (event) => {
                if (event.code === 1005) {
                    console.log('Socket manually closed');
                    emit({ type: 'deviceWebSocket/status/closed' });
                    emit(END);
                } else {
                    console.log(
                        'Socket is closed Unexpectedly. Reconnect will be attempted in 5 second.',
                        event.reason
                    );
                    emit({ type: 'deviceWebSocket/status/closed' });
                    setTimeout(() => {
                        createWS();
                        emit({ type: 'deviceWebSocket/status/reconnect' });
                    }, 5000);
                }
            };
        };

        createWS();
        return () => {
            // console.info('Socket is closed!!!');
            if (window.deviceWebSocket === undefined) return;

            window.deviceWebSocket.close();
        };
    });

    try {
        while (true) {
            const action = yield take(webSocketChannel);

            if (action.type === 'deviceWebSocket/onMessage') {
                const { type, data, systemId } = action.payload;

                switch (type) {
                    case 'subscribe_status':
                        window.deviceSubscriptions = data.systemIds;
                        break;
                    case 'online_status':
                    case 'online_state':
                        yield put(updateDeviceState({ systemId, online: data.online }));
                        yield put(reloadAlarms());
                        break;
                    case 'pong':
                        window.socketId = data.socketId;
                        break;
                    case 'alarm_states':
                        yield put(updateAlarmState({ systemId, hasAlarm: !isEmpty(data) }));
                        yield put(reloadAlarms());
                        break;
                    default:
                        break;
                }
            }

            yield put(action);
        }
    } catch (err) {
        console.error(err);
    } finally {
        if (yield cancelled()) {
            webSocketChannel.close();
        }
    }
}

const subscribeToDevices = (systemIds) => {
    // list of devices to subscript on
    const subscribeTo = [];
    const unSubscribeFrom = [];

    // subscribe new devices
    systemIds.forEach((systemId) => {
        if (window.deviceSubscriptions.indexOf(systemId) === -1) {
            subscribeTo.push(systemId);
        } /* else {
            unSubscribeFrom.push(systemId);
        } */
    });

    // subscribe to devices
    if (subscribeTo.length > 0) {
        console.log(`Subscribing to ${subscribeTo.length} devices`);
        sendMessage(
            JSON.stringify({
                type: 'subscribe_to_devices',
                data: { systemIds: subscribeTo, functions: false, alarms: true, onlineState: true },
            })
        );
    }

    // unsubscribe from devices
    if (unSubscribeFrom.length > 0) {
        console.log(`Unsubscribing from ${unSubscribeFrom.length} devices`);

        sendMessage(
            JSON.stringify({
                type: 'unsubscribe_from_devices',
                data: { systemIds: unSubscribeFrom },
            })
        );
    }
};

const sendMessage = (message) => {
    if (window.deviceWebSocket && window.deviceWebSocket.readyState === 1) {
        window.deviceWebSocket.send(message);
    }
};

function* handleSubscriptions() {
    const requestChannel = yield actionChannel(['bravo/devices/getDevicesSuccess', 'deviceWebSocket/status/opened']);
    while (true) {
        const { payload, type } = yield take(requestChannel);

        switch (type) {
            case 'deviceWebSocket/status/opened': {
                // this is only applicable on a reconnect
                const systemIds = yield select(({ bravo }) => keys(bravo.devices.items));
                if (systemIds.length > 0) {
                    subscribeToDevices(systemIds);
                }
                break;
            }
            case 'bravo/devices/getDevicesSuccess':
                subscribeToDevices(keys(payload));
                break;
            default:
                break;
        }
    }
}

function* handleConnectionState() {
    let handleMessagesAsyncTask = null;
    let handleSubscriptionsAsyncTask = null;
    const requestChannel = yield actionChannel(['auth/user/setUser', 'auth/user/userLoggedOut']);

    while (true) {
        const { type } = yield take(requestChannel);

        switch (type) {
            case 'auth/user/setUser':
                handleMessagesAsyncTask = yield fork(listenMessages);
                handleSubscriptionsAsyncTask = yield fork(handleSubscriptions);
                break;
            case 'auth/user/userLoggedOut':
                yield cancel(handleMessagesAsyncTask);
                yield cancel(handleSubscriptionsAsyncTask);
                break;
            default:
                break;
        }
    }
}

function* handlePing() {
    while (true) {
        if (window.deviceWebSocket && window.deviceWebSocket.readyState === 1) {
            window.deviceWebSocket.send(
                JSON.stringify({
                    type: 'ping',
                    data: null,
                })
            );
        }
        yield delay(10000);
    }
}

export default function* deviceWebSocketSagas() {
    // prettier-ignore
    yield all([
        // start child processes
        fork(handleConnectionState),
        fork(handlePing),
    ]);
}
