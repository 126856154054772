import { createSlice } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import history from '@history';
import Axios from 'axios';
import { mailConfirmSuccess } from './mailConfirmSlice';

export const submitRegister = (model) => async (dispatch) => {
    dispatch(registerSetIsLoading(true));

    return Axios.create({
        baseURL: process.env.REACT_APP_AUTH_SERVER_API_HOST,
    })
        .post('api/users/create', {
            email: model.email,
            password: model.password,
            passwordConfirmation: model['password-confirm'],
            firstName: model.firstName,
            lastName: model.lastName,
            client: 'bravo',
            // company: model.company,
        })
        .then(() => {
            dispatch(mailConfirmSuccess(model.email));
            history.push('mail_confirm');
        })
        .catch((err) => {
            dispatch(showMessage({ message: 'Something went wrong.' }));
            dispatch(registerSetIsLoading(false));
        });
};

const initialState = {
    success: false,
    loading: false,
    email: null,
    error: {
        username: null,
        password: null,
    },
};

const registerSlice = createSlice({
    name: 'auth/register',
    initialState,
    reducers: {
        registerSetIsLoading: (state, action) => {
            state.loading = action.payload;
        },
        registerSuccess: (state, action) => {
            state.success = true;
            state.email = action.payload;
        },
        registerError: (state, action) => {
            state.success = false;
            state.error = action.payload;
        },
    },
    extraReducers: {},
});

export const { registerSuccess, registerError, registerSetIsLoading } = registerSlice.actions;

export default registerSlice.reducer;
