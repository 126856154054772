import { createSlice, createSelector } from '@reduxjs/toolkit';
import history from '@history';
import _ from '@lodash';
import { setDefaultSettings, setInitialSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import jwtService from 'app/services/jwtService';
import Axios from 'axios';

const selectSelf = (state) => state.auth;
export const selectUser = createSelector(selectSelf, (state) => state.user);
export const selectUserData = createSelector(selectUser, (state) => state.data);
export const selectUserLanguage = createSelector(selectUserData, (state) => state.lng);
export const selectActiveCompany = createSelector(selectUser, (state) => state.activeCompany);

export const setUserData = (user) => async (dispatch, getState) => {
    /*
    You can redirect the logged-in user to a specific route depending on his role
    */
    // history.location.state = {
    //     redirectUrl: user.redirectUrl, // for example 'apps/academy'
    // };
    /*
    Set User Settings
     */
    dispatch(setDefaultSettings(user.data.settings));
    dispatch(setUser(user));
};

export const updateUserSettings = (settings) => async (dispatch, getState) => {
    const oldUser = getState().auth.user;
    const user = _.merge({}, oldUser, { data: { settings } });

    dispatch(updateUserData(user));

    return dispatch(setUserData(user));
};

export const updateUserShortcuts = (shortcuts) => async (dispatch, getState) => {
    const { user } = getState().auth;
    const newUser = {
        ...user,
        data: {
            ...user.data,
            shortcuts,
        },
    };

    dispatch(updateUserData(user));

    return dispatch(setUserData(newUser));
};

export const logoutUser = () => async (dispatch, getState) => {
    const { user } = getState().auth;

    if (!user.role || user.role.length === 0) {
        // is guest
        return null;
    }

    history.push({
        pathname: '/',
    });

    switch (user.from) {
        default: {
            jwtService.logout();
        }
    }

    dispatch(setInitialSettings());

    dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
    if (!user.role || user.role.length === 0) {
        // is guest
        return;
    }
    switch (user.from) {
        default: {
            jwtService
                .updateUserData(user)
                .then(() => {
                    dispatch(showMessage({ message: 'User data saved with api' }));
                })
                .catch((error) => {
                    dispatch(showMessage({ message: error.message }));
                });
            break;
        }
    }
};

const initialState = {
    role: [], // guest
    activeCompany: null,
    data: {
        displayName: '',
        photoURL: '',
        lng: 'en',
        email: '',
        shortcuts: [],
    },
};

export const setActiveCompany = (company, reload = false) => async (dispatch, getState) => {
    // TODO: this should be done better
    try {
        const url = `/api/companies/${company.id}/profile`;
        const response = await Axios.get(url);
        const companyProfile = await response.data;

        // merge data with company
        dispatch(
            updateActiveCompany({
                ...company,
                ...companyProfile,
            })
        );
    } catch (err) {
        console.error(err);
    }

    // also store in local storage
    localStorage.setItem('prefered_company_id', company.id);

    if (reload) {
        setTimeout(() => {
            history.push('/apps/devices-app/devices');
            //document.location.href = '/';
        }, 100);
    }
};

const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (state, action) => action.payload,
        userLoggedOut: (state, action) => initialState,
        updateActiveCompany: (state, action) => {
            state.activeCompany = action.payload;
        },
    },
    extraReducers: {},
});

export const getActiveCompany = () => ({ auth }) => auth.user.activeCompany;

export const { setUser, userLoggedOut, updateActiveCompany } = userSlice.actions;

export default userSlice.reducer;
