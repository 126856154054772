import Axios from 'axios';
import { put, takeLatest, select } from 'redux-saga/effects';
import { getDevicesFailed, getDevicesStart, getDevicesSuccess } from 'app/store/bravo/devicesSlice';
import { selectActiveCompany } from '../../auth/store/userSlice';

function* loadDevicesAsync(action) {
    try {
        console.info(action);
        yield put(getDevicesStart());
        const activeCompany = yield select(selectActiveCompany);
        const response = yield Axios.get(`/api/companies/${activeCompany.id}/devices`);
        const data = yield response.data;
        yield put(getDevicesSuccess(data));
    } catch (err) {
        yield put(getDevicesFailed(err));
    }
}

export default function* userSagas() {
    yield takeLatest('bravo/devices/getDevices', loadDevicesAsync);
    yield takeLatest('auth/user/updateActiveCompany', loadDevicesAsync);
}
